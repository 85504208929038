<template>
  <b-modal
    id="share-modal"
    :title="$t('share.title')"
    :visible="visible"
    size="sm"
    hide-footer
    no-close-on-backdrop
    @hidden="reset"
    @show="morphType = [6]"
    @change="$emit('change', false)"
  >
    <div class="d-flex justify-content-around mt-1">
      <!-- Community Share-->
      <div v-if="canShare">
        <div
          class="share-background d-block mx-auto"
          :class="{ 'share-background--active': isCommunityActive }"
          @click="handleSelectShare('community')"
        >
          <feather-icon icon="CompassIcon" size="30" class="d-block mx-auto" />
        </div>
        <h5 class="mt-50">
          {{ $t('share.community-title') }}
        </h5>
      </div>
      <!-- Clipboard Share-->
      <div>
        <div
          class="share-background d-block mx-auto"
          :class="{ 'share-background--active': isClipboardActive }"
          @click="handleSelectShare('clipboard')"
        >
          <feather-icon icon="CopyIcon" size="30" class="d-block mx-auto" />
        </div>
        <h5 class="mt-50">
          {{ $t('share.clipboard-title') }}
        </h5>
      </div>
      <!-- Social media Share-->
      <div>
        <div
          class="share-background d-block mx-auto"
          :class="{ 'share-background--active': isSocialActive }"
          @click="handleSelectShare('social')"
        >
          <feather-icon icon="Share2Icon" size="30" class="d-block mx-auto" />
        </div>
        <h5 class="mt-50">
          {{ $t('share.social-title') }}
        </h5>
      </div>
    </div>
    <hr>
    <!-- Content -->
    <b-collapse v-model="isCommunityActive" class="my-2" appear>
      <share-communities-select
        :type="type"
        :model-key="modelKey"
        :model-type="modelType"
        @morph-type-updated="handleMorphTypeUpdated()"
        @change="handleCloseModal"
      />
    </b-collapse>
    <b-collapse v-model="isClipboardActive" class="my-2" appear>
      <b-alert show variant="primary" class="text-center">
        {{ $t('share.copied-message') }}
      </b-alert>
    </b-collapse>
    <b-collapse v-model="isSocialActive" class="my-2" appear>
      <p>{{ $t('share.social-message') }}:</p>

      <div class="d-flex justify-content-around">
        <facebook
          :url="urlShare"
          :title="typeof(itemName) === 'object' ? Object.values(itemName)[0] : itemName"
          scale="2"
          class="social-buttons"
        />
        <twitter
          :url="urlShare"
          :title="typeof(itemName) === 'object' ? Object.values(itemName)[0] : itemName"
          scale="2"
          class="social-buttons"
        />
        <linkedin
          :url="urlShare"
          :title="typeof(itemName) === 'object' ? Object.values(itemName)[0] : itemName"
          scale="2"
          class="social-buttons"
        />
        <whats-app
          :url="urlShare"
          :title="typeof(itemName) === 'object' ? Object.values(itemName)[0] : itemName"
          scale="2"
          class="social-buttons"
        />
        <pinterest
          :url="urlShare"
          :title="typeof(itemName) === 'object' ? Object.values(itemName)[0] : itemName"
          scale="2"
          class="social-buttons"
        />
      </div>
    </b-collapse>
  </b-modal>
</template>
<script>
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';
import {
  Facebook, Twitter, Linkedin, Pinterest, WhatsApp,
} from 'vue-socialmedia-share';
import ShareCommunitiesSelect from '@/@core/components/ShareCommunitiesSelect.vue'

export default {
  name: 'ShareModal',
  components: {
    Facebook,
    Twitter,
    Linkedin,
    Pinterest,
    WhatsApp,
    ShareCommunitiesSelect,
  },
  mixins: [ToastNotificationsMixin],
  model: {
    prop: 'visible',
    event: 'change',
  },
  props: {
    visible: Boolean,
    itemName: {
      type: String,
      required: false,
    },
    modelType: {
      type: String,
      required: true,
    },
    modelKey: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    mediaType: {
      type: String,
      required: false,
      default: 'audio',
    },
    canShare: Boolean,
  },
  data() {
    return {
      isCommunityActive: false,
      isClipboardActive: false,
      isSocialActive: false,
      communitySelected: '',
      morphType: [],
      callingAgain: false,
    };
  },
  computed: {
    urlShare() {
      return window.location.href;
    },
  },
  watch: {
    callingAgain(value) {
      return value;
    },
  },
  methods: {
    handleMorphTypeUpdated() {
      this.callingAgain = true;
    },
    handleSelectShare(share) {
      switch (share) {
        case 'community':
          this.isCommunityActive = true;
          this.isClipboardActive = false;
          this.isSocialActive = false;
          break;
        case 'clipboard':
          this.isCommunityActive = false;
          this.isSocialActive = false;
          this.isClipboardActive = true;
          this.handleClipboardCopied();
          break;
        case 'social':
          this.isCommunityActive = false;
          this.isClipboardActive = false;
          this.isSocialActive = true;
          break;
        default:
          this.isCommunityActive = false;
          this.isClipboardActive = false;
          this.isSocialActive = false;
      }
    },
    handleClipboardCopied() {
      return navigator.clipboard.writeText(window.location.href);
    },
    communitiesFilter(response) {
      const communityOptions = [];
      for (const community of response) {
        if (community) {
          communityOptions.push({ code: community.key, name: community.name });
        }
      }
      return communityOptions;
    },
    async share() {
      try {
        const key = {};
        key[`${this.modelType}Key`] = this.modelKey;
        const requestConfig = {
          ...key,
          sharedCommunityKey: this.communitySelected,
        };
        if (this.type === 'media') {
          requestConfig.mediaType = this.mediaType;
        }
        await this.$store.dispatch('postItem', {
          type: `/${this.type}/share`,
          requestConfig,
        });
        this.$emit('change', false);
        this.notifySuccess(this.$t('share.notify-success'));
      } catch (error) {
        this.notifyError(this.$t('share.notify-error'));
      }
    },
    handleCloseModal() {
      this.$bvModal.hide('share-modal');
      this.reset();
    },
    reset() {
      this.isCommunityActive = false;
      this.isClipboardActive = false;
      this.isSocialActive = false;
      this.communitySelected = '';
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-include'; // Bootstrap includes
.share-background {
  background-color: lighten($text-muted, 40%);
  border: solid $text-muted 1px;
  width: 70px;
  padding: 18px 10px;
  border-radius: 1.2rem;
  cursor: pointer;
  &--active {
    background-color: lighten($primary, 40%);
    border: solid $primary 1px;
    color: $primary;
    cursor: pointer;
  }
}
.social-buttons {
  cursor: pointer;
}
</style>
